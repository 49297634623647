import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import Seo from "../components/seo";
import TopTitleBar from "../components/TopTitleBar";
import MaxWidthSection from "../components/Layout/MaxWidthSection";

const ApplicationStyles = styled.main`
  grid-column: 1 / span 14;
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, 5.2rem)) 1fr;
  gap: 0 2rem;
  margin: 0;
  padding: 4em 0;

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 1 / span 8;
    grid-template-columns: 2rem repeat(6, 1fr) 2rem;
    gap: 0 1rem;
    margin: 0;
  }
  @media ${(props) => props.theme.breakpoints.s} {
    grid-template-columns: 1rem repeat(6, 1fr) 1rem;
    gap: 0 1rem;
    margin: 0;
  }
  position: relative;
  .text {
    grid-column-end: span 8;
    position: relative;
    z-index: 10;

    @media ${(props) => props.theme.breakpoints.m} {
      grid-column-end: span 6;
      text-align: center;
    }
  }
  .download-application {
    grid-column-end: span 4;
    display: flex;
    align-items: center;
    justify-content: center;
    @media ${(props) => props.theme.breakpoints.m} {
      grid-column-end: span 6;
    }
  }
`;

const Application = () => {
  return (
    <Layout>
      <Seo title="Application" />
      <TopTitleBar title="Application" />
      <ApplicationStyles>
        <MaxWidthSection>
          <div className="text">
            <h2>Application for our prospective renters</h2>
            <p>
              Thank you for showing interest in one of our apartments! You can
              download an application here.
            </p>
          </div>
          <div className="download-application">
            <a
              href={'../../J&J_Lease_Application.xlsx'}
              download
              className="btn btn-secondary"
            >
              Download
            </a>
          </div>
        </MaxWidthSection>
      </ApplicationStyles>
    </Layout>
  );
};

export default Application;
